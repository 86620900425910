import React from "react"
import PropTypes from "prop-types"

// import Button from "../components/button"
import headerImage from "../images/header.png"
import deviceLight from "../images/device-light.png"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "fixed",
        top: 50,
        zIndex: -5,
        height: "200vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>{siteTitle}</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      A simple app to quickly estimate what you can afford. Coming <span style={{ textDecoration: "line-through",  opacity: 0.4}}>soon</span> probably never. 😏
    </p>
    {/* <Button>Get Early Access</Button> */}
    <div style={{ margin: 60, marginBottom: -50, width: `350px`, position: "relative" }}>
      
        <img
          src={deviceLight}
          alt="outlines of shapes and confetti in the background "
        />
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
